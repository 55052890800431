@import '../../node_modules/flickity/css/flickity.css'

.flickity-page-dots 
    position: absolute
    width: 100%
    bottom: -25px
    padding: 0
    margin: 0
    list-style: none
    text-align: center
    line-height: 1
    
.flickity-page-dots .dot
    display: inline-block
    width: 10px
    height: 10px
    margin: 0 8px
    background: theme('colors.grey.600')
    border-radius: 50%
    opacity: 0.25
    cursor: pointer

.flickity-page-dots .dot.is-selected
    opacity:1

.arrow
    color: theme('colors.grey.600')