
// Animation
.pulse 
    box-shadow: 0 0 0 rgba(54, 179, 126, 1)
    animation: pulse 1s infinite

.pulse:hover 
    animation: pulse 1s infinite


@-webkit-keyframes pulse 
    0% 
        -webkit-box-shadow: 0 0 0 0 rgba(54, 179, 126, 1)
    
    70% 
        -webkit-box-shadow: 0 0 0 20px rgba(54, 179, 126, 0)
    
    100% 
        -webkit-box-shadow: 0 0 0 0 rgba(54, 179, 126, 0)
    
    
@keyframes pulse 
    0% 
        -moz-box-shadow: 0 0 0 0 rgba(54, 179, 126, 1)
        box-shadow: 0 0 0 0  rgba(54, 179, 126, 1)
    
    70% 
        -moz-box-shadow: 0 0 0 12px rgba(54, 179, 126, 0)
        box-shadow: 0 0 0 12px rgba(54, 179, 126, 0)
    
    100% 
        -moz-box-shadow: 0 0 0 0  rgba(54, 179, 126, 0)
        box-shadow: 0 0 0 0  rgba(54, 179, 126, 0)
    