@import '../../node_modules/flickity/css/flickity.css';
@tailwind base;
.pulse {
  box-shadow: 0 0 0 rgb(54, 179, 126);
  animation: pulse 1s infinite;
}

.pulse:hover {
  animation: pulse 1s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(54, 179, 126);
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(54, 179, 126, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(54, 179, 126, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(54, 179, 126);
    box-shadow: 0 0 0 0 rgb(54, 179, 126);
  }
  70% {
    -moz-box-shadow: 0 0 0 12px rgba(54, 179, 126, 0);
    box-shadow: 0 0 0 12px rgba(54, 179, 126, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(54, 179, 126, 0);
    box-shadow: 0 0 0 0 rgba(54, 179, 126, 0);
  }
}
input,
input[class=text-grey-600] {
  color: theme("colors.grey.600");
  text-shadow: 0px 0px 0px #000;
  text-fill-color: theme("colors.grey.600") !important;
}

input[class=text-grey-100] {
  color: theme("colors.grey.100");
  text-shadow: 0px 0px 0px #000;
  text-fill-color: theme("colors.grey.100") !important;
}

input[class=text-grey-600] {
  color: theme("colors.grey.600");
  text-shadow: 0px 0px 0px #000;
  text-fill-color: theme("colors.grey.600") !important;
}

input[class=text-grey-600] {
  color: theme("colors.grey.600");
  text-shadow: 0px 0px 0px #000;
  text-fill-color: theme("colors.grey.600") !important;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: theme("colors.grey.500");
  text-shadow: none;
  text-fill-color: initial;
}

input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input:focus,
select {
  font-family: theme("fontFamily.sans");
}

select:focus,
textarea:focus,
button:focus,
input:focus {
  outline: none;
}

textarea {
  resize: none;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.auth-panel {
  width: 340px;
  min-height: 340px;
}

.auth-panel-sm {
  width: 360px;
  min-height: 360px;
}

.auth-panel-footnote {
  width: 340px;
  height: 2.5rem;
}

.auth-panel-footnote-sm {
  width: 360px;
  height: auto;
}

@media (min-width: 576px) {
  .auth-panel {
    width: 500px;
    min-height: 300px;
  }
  .auth-panel-footnote {
    width: 500px;
  }
}
@media (min-width: 768px) {
  .auth-panel {
    width: 384px;
    min-height: 384px;
  }
  .auth-panel-footnote {
    width: 384px;
  }
}
@media (min-width: 992px) {
  .auth-panel {
    width: 512px;
    min-height: 384px;
  }
  .auth-panel-footnote {
    width: 512px;
  }
}
@media (min-width: 1200px) {
  .auth-panel {
    width: 640px;
    min-height: 380px;
  }
  .auth-panel-footnote {
    width: 640px;
  }
}
.fade-enter-active {
  transition: all 0.2s ease;
}

.fade-leave-action {
  transition: all 0.4s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  z-index: 0;
}

.drawer-enter-active,
.drawer-leave-active {
  transition: all 0.2s ease;
}

.drawer-enter,
.drawer-leave-to {
  transform: translateX(-100%);
  opacity: 0;
  z-index: 16;
}

.status-enter-active {
  transition: all 0.5s ease;
}

.status-leave-active {
  transition: all 0.3s ease;
}

.status-enter {
  transform: translateY(-200%);
  opacity: 0;
  z-index: 0;
}

.status-leave-to {
  transform: translateY(200%);
  opacity: 0;
  z-index: 0;
}

.panel-enter-active,
.panel-leave-active {
  transition: all 0.3s ease;
}

.panel-enter,
.panel-leave-to {
  opacity: 0;
  transform: translateY(-100%);
  z-index: 0;
}

.notification-enter-active {
  transition: all 0.5s ease;
}

.notification-leave-active {
  transition: all 0.3s ease;
}

.notification-enter,
.notification-leave-to {
  transform: translateY(150%);
}

.enter-top-enter-active,
.enter-top-leave-active {
  transition: all 0.5s ease;
}

.enter-top-enter {
  transform: translateY(-100%);
  opacity: 0;
  z-index: 1;
}

.enter-top-leave-to {
  transform: translateY(-100%);
  opacity: 0;
  z-index: 1;
}

.enter-bottom-enter-active,
.enter-bottom-leave-active {
  transition: all 0.5s ease;
}

.enter-bottom-enter {
  transform: translateY(100%);
  opacity: 0;
  z-index: 1;
}

.enter-bottom-leave-to {
  transform: translateY(100%);
  opacity: 0;
  z-index: 1;
}

.per-person-enter-active,
.per-person-leave-active {
  transition: all 0.2s ease;
}

.per-person-enter,
.per-person-leave-to {
  transform: translateX(-100%);
  opacity: 0;
  z-index: 16;
}

@font-face {
  font-family: "Rubik-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("/../../../fonts/Rubik/Rubik-Regular.ttf");
}
@font-face {
  font-family: "Rubik-Light";
  font-style: light;
  font-weight: 300;
  src: url("/../../../fonts/Rubik/Rubik-Light.ttf");
}
@font-face {
  font-family: "Rubik-Bold";
  font-style: bold;
  font-weight: 700;
  src: url("/../../../fonts/Rubik/Rubik-Bold.ttf");
}
@font-face {
  font-family: "DM_Serif_Display";
  font-style: medium;
  font-weight: 500;
  src: url("/../../../fonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf");
}
h1 {
  font-size: 2.25rem;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
}

h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

h4 {
  font-size: 1.25rem;
  font-weight: bold;
}

h5 {
  font-size: 1rem;
  font-weight: bold;
}

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: theme("colors.grey.600");
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.arrow {
  color: theme("colors.grey.600");
}

@tailwind components;
@tailwind utilities;