.fade-enter-active
    transition: all 0.2s ease
.fade-leave-action
    transition: all 0.4s ease
.fade-enter,
.fade-leave-to
    opacity: 0
    z-index: 0
    
.drawer-enter-active,
.drawer-leave-active
    transition: all 0.2s ease

.drawer-enter,
.drawer-leave-to
    transform: translateX(-100%)
    opacity: 0
    z-index: 16

.status-enter-active
    transition: all 0.5s ease
.status-leave-active
    transition: all 0.3s ease

.status-enter
    transform: translateY(-200%) 
    opacity: 0
    z-index: 0
.status-leave-to
    transform: translateY(200%) 
    opacity: 0
    z-index: 0

.panel-enter-active, 
.panel-leave-active
    transition: all 0.3s ease

.panel-enter,
.panel-leave-to
    opacity: 0
    transform: translateY(-100%) 
    z-index: 0  

.notification-enter-active 
    transition: all 0.5s ease
.notification-leave-active
    transition: all 0.3s ease

.notification-enter,
.notification-leave-to
    transform: translateY(+150%) 

.enter-top-enter-active,
.enter-top-leave-active
    transition: all 0.5s ease

.enter-top-enter
    transform: translateY(-100%)
    opacity: 0
    z-index: 1
.enter-top-leave-to
    transform: translateY(-100%)
    opacity: 0
    z-index: 1

.enter-bottom-enter-active,
.enter-bottom-leave-active
    transition: all 0.5s ease

.enter-bottom-enter
    transform: translateY(100%)
    opacity: 0
    z-index: 1
.enter-bottom-leave-to
    transform: translateY(100%)
    opacity: 0
    z-index: 1    

.per-person-enter-active,
.per-person-leave-active
    transition: all 0.2s ease

.per-person-enter,
.per-person-leave-to
    transform: translateX(-100%)
    opacity: 0
    z-index: 16  

    