@font-face
    font-family: 'Rubik-Regular'
    font-style: normal
    font-weight: 400
    src: url('/../../../fonts/Rubik/Rubik-Regular.ttf')

@font-face
    font-family: 'Rubik-Light'
    font-style: light
    font-weight: 300
    src: url('/../../../fonts/Rubik/Rubik-Light.ttf') 

@font-face
    font-family: 'Rubik-Bold'
    font-style: bold
    font-weight: 700
    src: url('/../../../fonts/Rubik/Rubik-Bold.ttf')    

@font-face 
    font-family: 'DM_Serif_Display'
    font-style: medium
    font-weight: 500
    src: url('/../../../fonts/DM_Serif_Display/DMSerifDisplay-Regular.ttf')

h1
    font-size: 2.25rem
    font-weight: bold

h2 
    font-size: 2rem
    font-weight: bold

h3 
    font-size: 1.5rem
    font-weight: bold

h4
    font-size: 1.25rem
    font-weight: bold
    
h5
    font-size: 1rem
    font-weight: bold

  