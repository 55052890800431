input,

input[class="text-grey-600"] 
    color: theme('colors.grey.600')
    text-shadow: 0px 0px 0px #000
    text-fill-color: theme('colors.grey.600') !important
    
input[class="text-grey-100"] 
    color: theme('colors.grey.100')
    text-shadow: 0px 0px 0px #000
    text-fill-color: theme('colors.grey.100') !important   
    
input[class="text-grey-600"] 
    color: theme('colors.grey.600')
    text-shadow: 0px 0px 0px #000
    text-fill-color: theme('colors.grey.600') !important   

input[class="text-grey-600"] 
    color: theme('colors.grey.600')
    text-shadow: 0px 0px 0px #000
    text-fill-color: theme('colors.grey.600') !important      

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder
    color: theme('colors.grey.500')
    text-shadow: none
    text-fill-color: initial

input::-webkit-calendar-picker-indicator 
    filter: invert(1)

input:focus,

select 
    font-family: theme('fontFamily.sans')   
     
select:focus,
textarea:focus,
button:focus,
input:focus
    outline: none

textarea
    resize: none   

.disabled
    opacity: 0.5
    cursor: not-allowed

.auth-panel
    width: 340px
    min-height: 340px

.auth-panel-sm
    width: 360px
    min-height: 360px    

.auth-panel-footnote
    width: 340px
    height: 2.5rem
    
.auth-panel-footnote-sm
    width: 360px
    height: auto  

@media (min-width: 576px)
    .auth-panel
        width: 500px
        min-height: 300px

    .auth-panel-footnote
        width: 500px

@media (min-width: 768px)
    .auth-panel
        width: 384px
        min-height: 384px

    .auth-panel-footnote
        width: 384px

@media (min-width: 992px)
    .auth-panel
        width: 512px
        min-height: 384px

    .auth-panel-footnote
        width: 512px

@media (min-width: 1200px)
    .auth-panel
        width: 640px
        min-height: 380px

    .auth-panel-footnote
        width: 640px
